
const { PixelStreaming,Config,NumericParameters,Flags, OptionParameters } = require('@epicgames-ps/lib-pixelstreamingfrontend-ue5.5');
const { Application } = require('@epicgames-ps/lib-pixelstreamingfrontend-ui-ue5.5');
const CustomApplication = require('./CustomApplication'); 
const { isFirefox ,isAndroid, isIOS,} = require ('react-device-detect');





function getRandom4DigitNumber() {
    return Math.floor(Math.random() * 9000) + 1000;
}
let message ;

function showLoadingSpinner() {
    const spinner = document.createElement('div');
    spinner.classList.add('loading-spinner');
    spinner.style.position = 'absolute';
    spinner.style.top = '35%';
    spinner.style.left = '50%';
    spinner.style.transform = 'translate(-50%, -50%)';
    spinner.style.width = '60px';
    spinner.style.height = '60px';
    
    // SVG animation
    spinner.innerHTML = `
        <svg width="60" height="60" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="40" stroke="#3498db" stroke-width="8" fill="none" />
            <circle cx="50" cy="50" r="40" stroke="#f3f3f3" stroke-width="8" fill="none" stroke-dasharray="251" stroke-dashoffset="50" transform="rotate(-90 50 50)">
                <animate attributeName="stroke-dashoffset" values="251;0" dur="1s" repeatCount="indefinite"/>
            </circle>
        </svg>
    `;

    document.body.appendChild(spinner);
}

function hideLoadingSpinner() {
    const spinner = document.querySelector('.loading-spinner');
    if (spinner) {
        spinner.remove();
    }
}

function StreamPixelApplication(settings) {

    console.log("RESX:",settings.resX);
    console.log("RESY:",settings.resY);


  //  showLoadingSpinner();

    let ssUrl;

    if (settings.region === "US-East-1") {
        ssUrl = "wss://us1signalling.streampixel.io/?StreamerId="+settings.streamerId+"&ProjectId="+settings.appId+"&resX="+settings.resX+"&resY="+settings.resY;
    } else if (settings.region === "Europe") {
        ssUrl = "wss://eu1signalling.streampixel.io/?StreamerId="+settings.streamerId+"&ProjectId="+settings.appId+"&resX="+settings.resX+"&resY="+settings.resY;
    } else {
        ssUrl = "wss://signalling.streampixel.io/?StreamerId="+settings.streamerId+"&ProjectId="+settings.appId+"&resX="+settings.resX+"&resY="+settings.resY;
    }

    

    const initialSettings = {
        AutoConnect: settings.AutoConnect,
        ss: ssUrl,
        WaitForStreamer: false,
        StreamerId:settings.streamerId,
        TouchInput:true,
        XRControllerInput:settings.xrInput,
      };


    const config = new Config({
       initialSettings,
    
    }); 


    console.log("PC:",settings.primaryCodec);
    console.log("FC:",settings.fallBackCodec);

    const browserSupportedCodecs = [];
    let selectedCodec;
                      
    if (!RTCRtpReceiver.getCapabilities) {
        browserSupportedCodecs.push('Only available on Chrome');
        return browserSupportedCodecs;
    }

    const matcher = /(VP\d|H26\d|AV1).*/;
    const codecs = RTCRtpReceiver.getCapabilities('video').codecs;

    codecs.forEach((codec) => {
        const str = codec.mimeType.split('/')[1] + ' ' + (codec.sdpFmtpLine || '');
        const match = matcher.exec(str);
        if (match !== null) {
            browserSupportedCodecs.push(str);
        }
    });

    console.log(browserSupportedCodecs);
    if (Array.isArray(browserSupportedCodecs) && settings.primaryCodec && settings.fallBackCodec) {
        const pCodeArray = browserSupportedCodecs.filter(item =>
            item.toLowerCase().includes(settings.primaryCodec.toLowerCase())
        );
        console.log("pCodeArray", pCodeArray);

        const hasAVInPrimary = pCodeArray.some(item => item.toLowerCase().includes("av"));
    
        if (hasAVInPrimary) {
            if (pCodeArray.length > 0 && pCodeArray[0] && pCodeArray[0].length < 6) {
                handleFallback();
            } else if (settings.unrealVersion > 5.3) {
                if (!isFirefox) {
                    config.setOptionSettingOptions("PreferredCodec", pCodeArray);
                }
                config.setOptionSettingValue(OptionParameters.PreferredCodec, pCodeArray[0]);
                selectedCodec = settings.primaryCodec;
            } else {
                handleFallback();
            }
        } else if (pCodeArray.length > 0) {
            if(!isFirefox){
            config.setOptionSettingOptions("PreferredCodec", pCodeArray);
            }
            config.setOptionSettingValue(OptionParameters.PreferredCodec, pCodeArray[0]);
            selectedCodec = settings.primaryCodec;

        } else {
            handleFallback();
        }
    } else {
        console.error("Invalid inputs: Ensure browserSupportedCodecs is an array and settings contain valid codecs.");
    }
    

    function handleFallback() {
        const fCodearray = browserSupportedCodecs.filter(item =>
            item.toLowerCase().includes(settings.fallBackCodec.toLowerCase())
        );
        console.log("fCodearray:", fCodearray);
    
        if (fCodearray.length === 0) {
            config.setOptionSettingOptions("PreferredCodec", browserSupportedCodecs);
            selectedCodec = "H264"; 
        } else {
            const hasAVInFallback = fCodearray.some(item => item.toLowerCase().includes("av"));
            
            if (hasAVInFallback) {
                if (fCodearray[0].length < 6) {
                    config.setOptionSettingOptions("PreferredCodec", browserSupportedCodecs);
                    selectedCodec = "H264";  
                } else if (settings.unrealVersion > 5.3) {
                    if (!isFirefox) {
                        config.setOptionSettingOptions("PreferredCodec", fCodearray);
                    }
                    config.setOptionSettingValue(OptionParameters.PreferredCodec, fCodearray[0]);
                    selectedCodec = settings.fallBackCodec;
                } else {
                    config.setOptionSettingOptions("PreferredCodec", browserSupportedCodecs);
                    selectedCodec = "H264";
                }
            } else {
                if (!isFirefox) {
                    config.setOptionSettingOptions("PreferredCodec", fCodearray);
                }
                if (fCodearray.length > 0) {
                    config.setOptionSettingValue(OptionParameters.PreferredCodec, fCodearray[0]);
                    selectedCodec = settings.fallBackCodec;
                } else {
                    selectedCodec = "H264";
                }
            }
        }
    
        console.log("Selected Codec:", selectedCodec);
    }
    
   
    config.setFlagEnabled(Flags.ForceTURN,true);
    config.setFlagEnabled(Flags.MatchViewportResolution,false);
    config.setFlagEnabled(Flags.TouchInput,true);
    config.setFlagEnabled(Flags.FakeMouseWithTouches,settings.fakeMouseWithTouches);
    config.setFlagEnabled(Flags.StartVideoMuted,true);
    config.setFlagEnabled(Flags.BrowserSendOffer,false);
    config.setFlagEnabled(Flags.AFKDetection,true);
    config.setFlagEnabled(Flags.ForceMonoAudio,false);
    config.setFlagEnabled(Flags.AutoPlayVideo,true);
    config.setFlagEnabled(Flags.HideUI,true);
    config.setFlagEnabled(Flags.WaitForStreamer,true);
    config.setFlagEnabled(Flags.GamepadInput,settings.gamepadInput);
    config.setFlagEnabled(Flags.HoveringMouseMode,settings.hoverMouse);
    config.setFlagEnabled(Flags.MouseInput,settings.mouseInput);
    config.setFlagEnabled(Flags.IsQualityController,true);
    config.setFlagEnabled(Flags.KeyboardInput,settings.keyBoardInput);
    config.setFlagEnabled(Flags.UseMic,settings.useMic);
    config.setFlagEnabled(Flags.AFKDetection, true);
    config.setNumericSetting(NumericParameters.WebRTCFPS,60);
    config.setNumericSetting(NumericParameters.MaxQP, 40);
    config.setNumericSetting(NumericParameters.MinQP, 20);
    config.setNumericSetting(NumericParameters.AFKTimeoutSecs, settings.afktimeout);
    config.setNumericSetting(NumericParameters.WebRTCMinBitrate, 100);
    config.setNumericSetting(NumericParameters.WebRTCMaxBitrate, 1000000);
    config.setNumericSetting(NumericParameters.StreamerAutoJoinInterval, 3000);
    config.setNumericSetting(NumericParameters.MaxReconnectAttempts, 0);
    const pixelStreaming = new PixelStreaming(config);



    
 //wso && wso.onmessage((msg)=>console.log(msg))

 //wso &&  wso.onclose((event)=>console.log(event))


    const uiOptions = {
        settingsPanelConfig:false,
        stream:pixelStreaming,
       
    }
    

    const appStream = new CustomApplication(uiOptions);

 

    console.log("selectedCodec:",selectedCodec);

/*
    if(selectedCodec == "AV1"){
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MinQuality 28');
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MaxQuality -1');


     }
     if(selectedCodec == "VP8"){
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MinQuality -1');
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MaxQuality -1');


     }
     if(selectedCodec == "H264"){
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MinQuality 28');
        appStream.stream.emitConsoleCommand('PixelStreaming.Encoder.MaxQuality 32');


     }
     */

     
let visibilityTimeout;

if(isAndroid || isIOS){
document.addEventListener('visibilitychange', function() {
  if (document.hidden) {
    console.log('User switched tabs');
    
    visibilityTimeout = setTimeout(() => {
     
      appStream.stream.disconnect();
    }, 60000);
  } else {
    
          if (visibilityTimeout) {
      clearTimeout(visibilityTimeout);
    }
  }
});
}

     appStream.stream.addEventListener(
        'afkWarningActivate',
        () =>{
             message = {
                "type": "stream-state",
                "value": "afkWarning"
              }
            

              console.log("afkWarningActivate");


            window.parent.postMessage(message, "*");
        }
            
    );



    appStream.showTextOverlay('Starting connection to Streampixel server, please wait');


    console.log("Starting connection to Streampixel server, please wait");

      message = {
        "type": "stream-state",
        "value": "connecting"
      }
      


    window.parent.postMessage(message, "*");

    appStream.onConnectAction = function() {
        appStream.showTextOverlay('Starting connection to Streampixel server, please wait');
        message = {
            "type": "stream-state",
            "value": "connecting"
          }
        window.parent.postMessage(message, "*");

    }


    
    appStream.onVideoInitialized = function() {

        const videoElement = appStream && appStream.stream.videoElementParent.querySelector("video");

        videoElement.style.zIndex = 100;

      

       
      appStream.stream.emitConsoleCommand('t.maxFPS 60');
      


      appStream.stream.emitConsoleCommand('PixelStreaming.WebRTC.MinBitrate 1000');



      

      //hideLoadingSpinner();

    
    };



    appStream.handleStreamerListMessage = function(messageStreamingList, autoSelectedStreamerId, wantedStreamerId){
       // const waitForStreamer = this.stream.config.isFlagEnabled(Flags.WaitForStreamer);
       // const isReconnecting = this.stream.isReconnecting();
       // let message = null;
       // let allowRestart = true;

       console.log("streamerId:",settings.streamerId);
       console.log("messageStreamingList1:",messageStreamingList);
     
       console.log("wantedStreamerId:",wantedStreamerId);
     
       console.log("autoSelectedStreamerId:",autoSelectedStreamerId);


    /*
        if (!autoSelectedStreamerId) {
            if (waitForStreamer && wantedStreamerId) {
                if (isReconnecting) {
                    message = `Waiting for ${wantedStreamerId} to become available.`;
                    allowRestart = false;
                } else {
                    message = `Gave up waiting for ${wantedStreamerId} to become available. Click to try again`;
                    if (messageStreamingList.ids.length > 0) {
                        message += ` or select a streamer from the settings menu.`;
                    }
                    allowRestart = true;
                }
            } else if (messageStreamingList.ids.length === 0) {
                if (isReconnecting) {
                    message = `Waiting for a streamer to become available.`;
                    allowRestart = false;
                } else {
                    message = `No streamers available. Click to try again.`;
                    allowRestart = true;
                }
            } else {
                message = `Multiple streamers available. Select one from the settings menu.`;
                allowRestart = false;
            }
    
            if (allowRestart) {
                this.showDisconnectOverlay(message);
            } else {
                this.showTextOverlay(message);
            }
        }
            */
    }
    
  
    appStream.onWebRtcConnecting = function() {

        console.log("Almost there, hold tight- awesomeness loading");


        appStream.showTextOverlay('Almost there, hold tight- awesomeness loading');
        message = {
            "type": "stream-state",
            "value": "Almost there, hold tight- awesomeness loading"
          }

        window.parent.postMessage(message, "*");

    }
    
    appStream.onWebRtcConnected = function() {
        appStream.showTextOverlay('Sharpening pixels and buffing the details...');

        console.log("Sharpening pixels and buffing the details...");

       // hideLoadingSpinner();
        message = {
            "type": "stream-state",
            "value": "finalising"
          }
        window.parent.postMessage(message, "*");

        
        }
   
       appStream.onWebRtcAutoConnect= function() {
       
            appStream.showTextOverlay('Disconnected');

            console.log("Disconnected");


            message = {
                "type": "stream-state",
                "value": "disconnectd"
              }
            window.parent.postMessage(message, "*");

        }

    
    
        appStream.onWebRtcSdp = function() {
            appStream.showTextOverlay('Sharpening pixels and buffing the details...');

            console.log("Sharpening pixels and buffing the details...");

            message = {
                "type": "stream-state",
                "value": "finalising"
              }
            window.parent.postMessage(message, "*");

    }



    var wsController = appStream.stream.signallingProtocol;
        
    window.addEventListener("offline", () => {
        console.log("Network offline: Closing WebSocket...");
    
        if (wsController.isConnected()) {
            console.log("WebSocket is still connected. Forcing closure...");
            
            if (wsController.ws && wsController.ws.readyState === WebSocket.OPEN) {
                wsController.ws.close();  
            }
    
            wsController.disconnect();
        }
    });
    
        
   
    var isConnected = wsController.isConnected();
   if(isConnected){
    
    wsController.transport.on('message', (msgRaw) => {
        try {
            
            if(msgRaw.message == "You are in Queue"){

                var msgFormate = msgRaw.message +" "+msgRaw.position;
appStream.showTextOverlay(msgFormate);


var iFMessage = "queue"+"-"+msgRaw.position
message = {
    "type": "stream-state",
    "value": iFMessage
  }

window.parent.postMessage(message, "*");

        
            }

            if (msgRaw.message === "Application Error") {
                const spinners = document.querySelectorAll('.loading-spinner');  
            
                spinners.forEach(spinner => {
                    spinner.style.display = 'none';  
                });
            
                var msgFormate = msgRaw.message;
            
                appStream.showTextOverlay(msgFormate);
            }
            

        } catch (error) {
            console.error("Failed to parse message:", error);
            return;
        }
    })

    wsController.transport.on('close',(reason)=>{
       appStream.showDisconnectOverlay();
    })

   }

    return {
        pixelStreaming,
        appStream
    };
}

module.exports = { StreamPixelApplication };
